export default function VisionQuoteImage({ imgSrc, imgAlt }) {
  return (
    <div className="relative lg:-my-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"></div>
      <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
        <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
          <img
            className="object-cover lg:h-full lg:w-full"
            src={imgSrc}
            alt={imgAlt}
            height="inherit"
            width="inherit"
          />
        </div>
      </div>
    </div>
  );
}
