import SVGQuote from '../SVGQuote/SVGQuote';
import VisionQuoteImage from '../VisionQuoteImage/VisionQuoteImage';
import VisionQuoteText from '../VisionQuoteText/VisionQuoteText';
import VisionQuoteFooter from '../VisionQuoteFooter/VisionQuoteFooter';
import VisionQuoteContentLayout from '../VisionQuoteContentLayout/VisionQuoteContentLayout';
import LazyLoad from 'react-lazyload';

const defaultVisionQuoteContent = {
  imgSrc:
    'https://res.cloudinary.com/tincre/image/upload/v1639598743/tearsheetads.com/AdobeStock_258012460_hd7qxc_ovxpdj.webp',
  imgAlt:
    `Two white earbuds on a robin's egg blue flat background. Listen to the next generation of clients and reach them across the web.`,
  quote: `Our ad technology engages the next-generation client where they work, live and play. We're enabling advisors and planners to do more with less resources, ultimately expanding access to high-quality financial services.`,
  name: 'Jason R. Stevens, CFA',
  titleDescription: <span>CTO - <a className="underline hover:text-blue-200" href='https://tincre.com' target='_blank' rel='noreferrer noopener'>Tincre</a></span>,
};
export default function VisionQuote({visionQuoteContent}) {
  const visionQuote =
    typeof visionQuoteContent !== 'undefined' && !!visionQuoteContent
      ? visionQuoteContent
      : defaultVisionQuoteContent;
  return (
    <LazyLoad height={1000} once offset={200}>
      <div className="pb-16 bg-gradient-to-r from-blue-500 to-blue-800 lg:pb-0 lg:relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
          <VisionQuoteImage {...visionQuote} />
          <VisionQuoteContentLayout>
            <div>
              <SVGQuote />
              <VisionQuoteText {...visionQuote} />
            </div>
            <VisionQuoteFooter {...visionQuote} />
          </VisionQuoteContentLayout>
        </div>
      </div>
    </LazyLoad>
  );
}
