export default function VisionQuoteContentLayout({ children }) {
  return (
    <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
      <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
        <blockquote className="hover:text-black hover:font-bold">
          {children}
        </blockquote>
      </div>
    </div>
  );
}
